<template>
<div id="calendar">
  <div style="margin: 10px">
    巡检人员：
    <el-select style="width:200px;" clearable class="EquipmentMarginLeft" v-model="paramsData.userId"  placeholder="巡检人员" @change="reloadList">
      <el-option
          v-for="item in maintenList"
          :key="item.id"
          :label="item.username"
          :value="item.id">
      </el-option>
    </el-select>
    时间范围：
    <el-date-picker
        :clearable="false"
        @change="reloadList"
        v-model="paramsData.yearMonth"
        type="month"
        value-format="yyyy-MM"
        placeholder="选择月份">
    </el-date-picker>
  </div>
  <el-calendar :value="paramsData.yearMonth" :range="['2021-11-1','2021-12-31']">
    <template
        slot="dateCell"
        slot-scope="{date, data}">
      <div>
        <div style="text-align: center;margin-top: 15px;font-size:18px;font-weight: bold;">
          {{ data.day.split('-').slice(1).join('-') }}
        </div>
        <template v-if="data.day.indexOf(paramsData.yearMonth)!=-1">
          <div v-for="(item,index) in calendarData" :key="index" style="position: relative">
            <div v-if="item.dayOfMonth==data.day.split('-').slice(2).join('-')">
              <i class="el-icon-document" style="position: absolute;right: 0;top: -50px;font-size: 22px" @click="seeDetil(item.dayOfMonth)"></i>
              <div class="is-selected">{{item.completedRate=='暂无'||item.completedRate=='0/0'?'暂无':'完成率：'+item.completedRate}}</div>
            </div>
          </div>
        </template>
      </div>
    </template>
  </el-calendar>
  <el-dialog
      title="任务详情"
      width="60%"
      :visible.sync="eventVisible"
  >
    <el-table ref="singleTable" :data="tableData" highlight-current-row style="width: 100%" height="500">
      <el-table-column property="id" align="center" label="序号"></el-table-column>
      <el-table-column property="taskCode" label="任务编号" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column property="taskName" label="任务名称" align="center" show-overflow-tooltip></el-table-column>
      <el-table-column property="executorPeopleName" label="巡检人"  align="center" show-overflow-tooltip></el-table-column>
      <el-table-column label="打卡点"  align="center" show-overflow-tooltip>
        <template slot-scope="{row}">
          {{row.allPush}}个（已打{{row.isPushNumber}}个）
        </template>
      </el-table-column>
    </el-table>
    <div style="text-align: center">
      <el-pagination
          :current-page="paramsData.current"
          :background="true"
          :page-sizes="[15, 30, 50, 100]"
          :page-size="paramsData.size"
          layout="total,sizes,prev,pager,next,jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>

    <span slot="footer" class="dialog-footer">
        <el-button @click="eventVisible = false">关 闭</el-button>
    </span>
  </el-dialog>
</div>
</template>

<script>
import {ByPostpeople, tasklist, taskpreview} from "../../RequestPort/maintenance";

export default {
  name: "taskDetail",
  data(){
    return{
      total:null,
      paramsData:{
        current:1,
        size:15,
        yearMonth:'',
        userId:''
      },
      eventVisible:false,
      maintenList:[],
      calendarData: [],
      tableData: [],
      day:''
    }
  },
  methods:{
    Change(date,data){
    },
    seeDetil(day){
      this.day = day
      let obj={
        date:this.paramsData.yearMonth+'-'+(this.day<10?'0'+this.day:this.day),
        userId:this.paramsData.userId
      }
      let p = {
        ...obj,
        ...this.paramsData
      }
      tasklist(p).then(res=>{
        if(res.code===200&&res.data.records.length>0){
          this.tableData = res.data.records
          this.total = res.data.total
          this.eventVisible = true
        }else {
          this.$message.success('暂无任务详情')
        }
      })
    },
    handleSizeChange(val) {
      this.paramsData.size = val
      this.seeDetil(this.day)
    },
    handleCurrentChange(val) {
      this.paramsData.current = val
      this.seeDetil(this.day)
    },
    reloadList(){
      this.loadlist(this.paramsData)
    },
    loadlist(obj){
      taskpreview(obj).then(res=>{
        this.calendarData = res.data
      })
    },
    initData(){
      let date = new Date();
      let year = date.getFullYear(); // 年
      let month = date.getMonth() + 1; // 月
      let newdata = year+'-'+(month<10?'0'+month:month)
      this.paramsData.yearMonth = newdata
    },
  },
  mounted() {
    this.initData()
    ByPostpeople({code:'pollingPerson'}).then(res=>{
      this.maintenList = res.data
      if(res.data){
        //this.paramsData.userId = res.data[0].id
        this.loadlist(this.paramsData)
      }

    })
  }
}
</script>

<style scoped>
.is-selected{
  text-align: center;
}
</style>
